<template>
  <div class="d-flex justify-content-center" style="flex-direction: column">
    <!-- <button
      type="button"
      class="btn btn-secondary btn-sm my-3"
      v-if="!visibility"
      @click="visibility = true"
    >
      {{ $trans("form-input.modifica") }}
      {{ $trans("form-input.indirizzo-spedizione") | lowercase }}
    </button> 
      v-else-->
    <div class="mb-1"><strong>Altro indirizzo di spedizione?</strong></div>
    <address-list
      :confirm="confirm"
      :for_cart="false"
      :client="client"
      :addresses="client.addresses"
      v-model="id_indirizzo"
      @nazioneChanged="nazioneChanged"
      :total-changed="totalChanged"
    />
    <div v-if="isLoading" class="overlay no-select">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AddressList from "./AddressList.vue";

export default {
  name: "EditShippingAddress",
  components: { AddressList },
  props: {
    orderid: {
      type: String|Number,
      require: false,
      default: '',
    },
    client: Object,
    confirm: {
      type: Boolean|Number,
      require: false,
      default: false,
    },
    isBusiness: {
      type: String|Number,
      default: 0,
    }
  },
  data() {
    return {
      isLoading: true,
      id_indirizzo: null,
      visibility: false,
      totalChanged: {
        changed: false,
      },
      suppl: null,
    };
  },
  watch: {
    id_indirizzo: function (newVal) {
      this.indirizzoChanged(newVal);
    },
    isLoading: function (value) {
      document.documentElement.style.overflowY = value ? "hidden" : "auto";
    },
  },
  mounted() {
    console.log('ORRR', this.orderid);
    axios
      .get(
          "/api/v1/cart/calc-shipping?" +
          "nazione=" + (this.client.nazione || "IT") +
          "&city=" + this.client.citta +
          "&cap=" + this.client.cap +
          "&metodo_evasione=NOWAIT" +
          "&tipo_spedizione=SP" +
          "&order_id=" + (this.orderid || '')
      )
      .then((response) => {
        this.suppl =
          parseFloat(
            document
              .querySelector("#costo_spedizione")
              .innerHTML.replace(",", ".")
          ) - response.data.data.shipping;
        console.log('AU',this.suppl, document
            .querySelector("#costo_spedizione")
            .innerHTML.replace(",", "."), response.data.data.shipping)
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    nazioneChanged(val) {
      //console.log(e);
    },
    indirizzoChanged(val) {
      this.isLoading = true;
      let address = this.client.addresses.filter(row => row.id == val);
      address = address.length ? address[0] : this.client;
      document.querySelector("#newAddress").value = JSON.stringify(address);

      console.log('addressselected', address, this.client);

      axios
        .get(
          "/api/v1/cart/calc-shipping?" +
            "nazione=" + (address.nazione || "IT") +
            "&city=" + address.citta +
            "&cap=" + address.cap +
            "&metodo_evasione=NOWAIT" +
            "&tipo_spedizione=SP" +
            "&order_id=" + (this.orderid || '')
        )
        .then((response) => {
          const tot_sel = document.querySelector("#tot_ordine");
          const shipment_default = parseFloat(
            tot_sel.getAttribute("data-shipment").replace(",", ".")
          );
          let shipment_new = parseFloat(response.data.data.shipping) + parseFloat(response.data.data.packaging);
          this.suppl = 0;

          this.totalChanged = {
            changed: shipment_default !== shipment_new,
            original: shipment_default,
            new: shipment_new,
          };
          document.querySelector("#newShipment").value = shipment_new;
          const totOrdine = parseFloat(tot_sel.getAttribute('data-total').replace(",", ".")) + response.data.data.shipping + response.data.data.packaging;
          if (this.isBusiness) {
            const vat = totOrdine - (totOrdine / ((100 + response.data.data.iva) / 100));
            shipment_new = shipment_new / ((100 + response.data.data.iva) / 100);
            document.querySelector('#imposta_iva').innerHTML = new Intl.NumberFormat("it-IT", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(vat) + "&nbsp;&euro;"
          }

          const ship_sel = document.querySelector("#costo_spedizione");
          ship_sel.innerHTML =
            new Intl.NumberFormat("it-IT", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(shipment_new + this.suppl) + "&nbsp;&euro;" + (this.isBusiness ? " + IVA" : "");
          tot_sel.innerHTML =
            new Intl.NumberFormat("it-IT", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(
                totOrdine
            ) + "&nbsp;&euro;";
        })
        .catch((error) => {
          CustomSwal({
            title: "Errore",
            html: error.response.data.errors.join("<br><br>"),
            confirmButtonText: "Ok",
            icon: "error",
          }).then(() => {});
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  filters: {
    lowercase(value) {
      return value.toLowerCase();
    },
  },
};
</script>

<style scoped>
</style>
